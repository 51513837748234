import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery , graphql } from "gatsby"


const SEOprops = (props) => {
	const data = useStaticQuery(graphql`
		query {
          site {
            siteMetadata {
              description
              lang
              title
			  siteUrl
            }
          }
		}
	`)
	
	const title = props.pagetitle
		? `${props.pagetitle}|${data.site.siteMetadata.title}`
		: data.site.siteMetadata.title
	const description = props.pagedesc || data.site.siteMetadata.description
	const url = props.pagepath
		? `${data.site.siteMetadata.siteUrl}${props.pagepath}`
		: data.site.siteMetadata.siteUrl
	
	return(
		<Helmet>
			<html lang={data.site.siteMetadata.lang} />
			<title>{title}</title>
			<meta name="description" content={description} />
		
			<link rel="canonical" href={url} />
		
			<meta property="og:site_name" content={data.site.siteMetadata.title} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={url} />
			<meta property="og:type" content="website" />
		</Helmet>
	)
}

export default SEOprops;
