import React from 'react'
import {Link} from "gatsby"

    


const productToggle = () => {
    
            const innerWidth = window.innerWidth
    
                    let pMore = document.getElementById("product-more")
                    let pMoreFlag = pMore.getAttribute('flag')
                    if(pMoreFlag === "+"){
                        if(innerWidth>=767){ //PC
                            document.getElementById("footer-logo").style.height="150px"
                            document.getElementById("footer-small").style.height="150px"
                            document.getElementById("footer-small").style.lineHeight="280px"
                        }else{ //SP
                            document.getElementById("footer-logo").style.height="285px"
                        }
                        document.getElementById("p-more1").style.width="0"
                        pMore.setAttribute('flag',"-")
                    }else{
                        if(innerWidth>=767){ //PC
                            document.getElementById("footer-logo").style.height="100px"
                            document.getElementById("footer-small").style.height="100px"
                            document.getElementById("footer-small").style.lineHeight="180px"
                        }else{ //SP
                            document.getElementById("footer-logo").style.height="190px"
                        }
                        document.getElementById("p-more1").style.width="15px"
                        pMore.setAttribute('flag',"+")
                    }
}



const footerPartsHtml = () => (
    <div>
		<Link to={`/`} className="footer-logo_wrap">
		  <div className="footer-logo" id="footer-logo"><div className="logo bg-img" /></div>
		</Link>
		<div className="footer-links_wrap">
		  <ul>
			<div>
			  <li><Link to={`/about/`}>ABOUT</Link></li>
			  <li>
                <Link to={`/product`}>PRODUCT</Link>
                <button className="product-more" id="product-more" data-toggle="collapse" data-target="#product-inner" flag="+" onClick={productToggle}>
                    <span id="p-more1" /><span />
                </button>
              </li>
              <div className="product-inner collapse" id="product-inner" data-parent="#product-more">
				  <Link to={`/sessaku/`}><div>- 切削強化</div></Link>
				  <Link to={`/kumikomi/`}><div>- 製品強化</div></Link>
				  <Link to={`/kensaku/`}><div>- 工程強化</div></Link>
              </div>
			</div>
			<div>
			  <li><Link to={`/blog/`}>BLOG</Link></li>
			  <li><Link to={`/contact/`}>CONTACT</Link></li>
			</div>
		    <div className="footer-small d-block d-md-none">
		      <Link to={`/policy/`} className="footer-policy">POLICY</Link>
		      <span>©MAZIN</span>
		    </div>{/*.footer-small*/}
		  </ul>
          
		</div>{/*.footer-links_wrap*/}
    
		<div className="footer-small d-none d-md-block" id="footer-small">
		  <Link to={`/policy/`} className="footer-policy">POLICY</Link>
		  <span>©MAZIN</span>
		</div>{/*.footer-small*/}
    
    <script
            dangerouslySetInnerHTML={{
                __html: `
            //(function() {

                //const setFillHeight = () => {
                //  const vh = window.innerHeight * 0.01
                //  document.documentElement.style.setProperty('--vh','{vh}px')
                //}
                //window.addEventListener('resize',setFillHeight)
                //setFillHeight()

                window.onscroll=function(){
                    let scrollTop = document.documentElement.scrollTop || // IE Firefox Opera
                                    document.body.scrollTop // Chrome Safari
                    let body = document.getElementById("body")
                    let wrap = document.getElementById("top-logo_wrap")
                    let onlyLogo = document.getElementById("logo_no-type")

                    const innerWidth = window.innerWidth
                    const innerHeight = window.innerHeight
                    const lastFullPage = innerHeight * 5/2
                    const afterFullPage = innerHeight * 7/2

                    //scrollSnap
                    if(innerWidth>=767){ //PC
                        if(scrollTop > afterFullPage){              //After
                            body.style.scrollSnapType="none"
                            body.style.scrollSnapPointsY="none"
                            body.style.scrollSnapType="none"
                        }else if(scrollTop > lastFullPage){          //FullPage P3
                            body.style.scrollSnapType="proximity"
                            body.style.scrollSnapPointsY="repeat(300px)"
                            body.style.scrollSnapType="y proximity"
                        }else{                                      //FullPage P1&2
                            body.style.scrollSnapType="mandatory"
                            body.style.scrollSnapPointsY="repeat(300px)"
                            body.style.scrollSnapType="y mandatory"
                        }
                    }else{ //SP
                        //if(scrollTop > afterFullPage){              //After
                            body.style.scrollSnapType="none"
                            body.style.scrollSnapPointsY="none"
                            body.style.scrollSnapType="none"
                        //}else                                       //FullPage P1&2&3
                        //    body.style.scrollSnapType="proximity"
                        //    body.style.scrollSnapPointsY="repeat(300px)"
                        //    body.style.scrollSnapType="y proximity"
                        //}
                    }


                    //top-logo縮小
                    if(scrollTop > innerHeight - 50){ //After
                        wrap.style.left="0"
                        wrap.style.width="50px"
                        wrap.style.height="50px"
                        onlyLogo.style.opacity="1"
                        onlyLogo.style.backgroundPosition="center"
                    }else{                            //Top
                        if(innerWidth>=767){ //PC
                            wrap.style.left="50px"
                        }else{ //SP
                            wrap.style.left="25px"
                        }
                        wrap.style.width="100px"
                        wrap.style.height="100px"
                        onlyLogo.style.opacity="0"
                        onlyLogo.style.backgroundPosition="top"
                    }

                }

//                //ハンバーガー
//                function menuToggle(){
//                    let toggleMenu = document.getElementById("nav-button")
//                    let toggleMenuFlag = toggleMenu.getAttribute('flag')
//                    if(toggleMenuFlag == "+"){
//                        document.getElementById("line1").style.transform="rotate(45deg)translateX(5px)"
//                        document.getElementById("line2").style.opacity="0"
//                        document.getElementById("line3").style.transform="rotate(-45deg)translateX(-5px)"
//                        toggleMenu.setAttribute('flag',"-")
//                    }else{
//                        document.getElementById("line1").style.transform="none"
//                        document.getElementById("line2").style.opacity="1"
//                        document.getElementById("line3").style.transform="none"
//                        toggleMenu.setAttribute('flag',"+")
//                    }
//                }
//                document.getElementById("nav-button").onclick=menuToggle;
                
//                //FooterProduct
//                function productToggle(){
//                    let pMore = document.getElementById("product-more")
//                    let pMoreFlag = pMore.getAttribute('flag')
//                    if(pMoreFlag == "+"){
//                        if(innerWidth>=767){ //PC
//                            document.getElementById("footer-logo").style.height="150px"
//                            document.getElementById("footer-small").style.height="150px"
//                            document.getElementById("footer-small").style.lineHeight="280px"
//                        }else{ //SP
//                            document.getElementById("footer-logo").style.height="285px"
//                        }
//                        document.getElementById("p-more1").style.width="0"
//                        pMore.setAttribute('flag',"-")
//                    }else{
//                        if(innerWidth>=767){ //PC
//                            document.getElementById("footer-logo").style.height="100px"
//                            document.getElementById("footer-small").style.height="100px"
//                            document.getElementById("footer-small").style.lineHeight="180px"
//                        }else{ //SP
//                            document.getElementById("footer-logo").style.height="190px"
//                        }
//                        document.getElementById("p-more1").style.width="15px"
//                        pMore.setAttribute('flag',"+")
//                    }
//                    console.log(pMore.getAttribute('flag'))
//                }
//                document.getElementById("product-more").onclick=productToggle;

            //}(document, 'script'))

            `
          }}
        />
    
    </div>
)

export default footerPartsHtml;
