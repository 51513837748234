import React from "react"
import { Helmet } from "react-helmet"
import {Link} from "gatsby"

   
const menuToggle = () => {
                //ハンバーガー
                    let toggleMenu = document.getElementById("nav-button")
                    let toggleMenuFlag = toggleMenu.getAttribute('flag')
                    if(toggleMenuFlag === "+"){
                        document.getElementById("line1").style.transform="rotate(45deg)translateX(5px)"
                        document.getElementById("line2").style.opacity="0"
                        document.getElementById("line3").style.transform="rotate(-45deg)translateX(-5px)"
                        toggleMenu.setAttribute('flag',"-")
                    }else{
                        document.getElementById("line1").style.transform="none"
                        document.getElementById("line2").style.opacity="1"
                        document.getElementById("line3").style.transform="none"
                        toggleMenu.setAttribute('flag',"+")
                    }
}    

     
const headerHtml = () => (
    
    
  <div>
	<Helmet>
		<script type="text/javascript" src="https://code.jquery.com/jquery-3.6.0.min.js" />
		<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous" />
		<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous" />
                
        <body id="body" />
	</Helmet>
	<header>
		<Link to={`/`}>
		  <div className="top-logo_wrap" id="top-logo_wrap"><div className="logo bg-img" /><div className="logo logo_no-type bg-img" id="logo_no-type" /></div>
		</Link>
		<div className="d-none d-md-block">
		  <div className="top-links_wrap">
			<ul>
			  <li><Link to={`/about/`}>ABOUT</Link></li>
			  <li>
				<Link to={`/product/`}>PRODUCT</Link>
				<div className="product-inner">
				  <Link to={`/sessaku/`}><div>切削強化</div></Link>
				  <Link to={`/kumikomi/`}><div>製品強化</div></Link>
				  <Link to={`/kensaku/`}><div>工程強化</div></Link>
				</div>
			  </li>
			  <li><Link to={`/blog/`}>BLOG</Link></li>
			  <li><Link to={`/contact/`}>CONTACT</Link></li>
			</ul>
		  </div>{/*.top-links_wrap*/}
		</div>
		<Link to={`/contact/`} className="top-contact bg-img"></Link>
		<div className="d-block d-md-none">
		  <button id="nav-button" data-toggle="collapse" data-target="#nav-collapse" aria-expanded="false" aria-controls="nav-collapse" flag="+" onClick={menuToggle}>
			<div className="icon-menu">
			  <span className="line-1 bg-img" id="line1" /><span className="line-2 bg-img" id="line2" /><span className="line-3 bg-img" id="line3" />
			</div>
		  </button>
		  <div className="collapse" id="nav-collapse">
            <div>
                <ul>
                  <li><Link to={`/`}>TOP</Link></li>
                  <li><Link to={`/about/`}>ABOUT</Link></li>
                  <li><Link to={`/product/`}>PRODUCT</Link></li>
                  <li className="sub-link"><Link to={`/sessaku/`}>切削強化</Link></li>
                  <li className="sub-link"><Link to={`/kumikomi/`}>製品強化</Link></li>
                  <li className="sub-link"><Link to={`/kensaku/`}>工程強化</Link></li>
                  <li><Link to={`/blog/`}>BLOG</Link></li>
                  <li><Link to={`/contact/`}>CONTACT</Link></li>
                </ul>
            </div>
		  </div>{/*.collapse*/}
		</div>
	  </header>
	</div>
        
        
)

export default headerHtml;